<template>
  <v-dialog
    min-width="250"
    :max-width="width"
    v-model="open"
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="button" :on="on" :attrs="{...attrs, disabled}"></slot>
    </template>

    <v-card>
      <v-card-title class="headline">
        <slot name="title">{{ $t('common.messages.are_you_sure') }}</slot>
      </v-card-title>

      <v-card-text v-if="$slots.message">
        <slot name="message"></slot>
      </v-card-text>

      <v-card-actions>
        <v-spacer/>

        <v-btn text color="primary" @click="confirm">
          <slot name="yes-button">{{ $t('common.words.yes') }}</slot>
        </v-btn>

        <v-btn text color="error" @click="close">
          <slot name="no-button">{{ $t('common.words.no') }}</slot>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'confirm-button',
  props: {
    width: { default: 250 },
    disabled: { type: Boolean, default: false },
  },
  data: () => ({
    open: false,
  }),
  methods: {
    confirm() {
      this.$emit('click');
      this.close();
    },
    close() {
      this.open = false;
    },
  },
};
</script>

<style scoped>
.v-card-title {
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
</style>
